import Link from "next/link";
import { useState, type Dispatch, type SetStateAction } from "react";
import EvalSummaryModal from "~/components/EvalSummaryModal";
import KPIs from "~/components/campaigns/MainPage/Dashboard/DashboardInsights/KPInsights";
import PrimaryInsights from "~/components/campaigns/MainPage/Dashboard/DashboardInsights/PrimaryInsights";
import RankInsights from "~/components/campaigns/MainPage/Dashboard/DashboardInsights/RankInsights";
import { useCampaign } from "~/providers/CampaignProvider";
import { useTeamAccount } from "~/providers/TeamAccountProvider";
import { useTeamAccountUser } from "~/providers/TeamAccountUserProvider";
import { TrackerProvider } from "~/providers/TrackerProvider";
import { api } from "~/utils/api";
import SecondaryInsights from "./DashboardInsights/SecondaryInsights";
import SelectDependency from "./DashboardInsights/SelectDependency";
import LightDashboard from "../Dashboard/DashboardInsights/LightDashboard";
import { InsightsPerTracker } from "@openqlabs/drm-db";
import CampaignEvalProgress from "../../Utils/CampaignEvalProgress";
import { Enums } from "@openqlabs/utils";

export default function Dashboard({
  setSubMenu,
}: Readonly<{
  setSubMenu: Dispatch<SetStateAction<string>>;
}>) {
  const campaign = useCampaign();
  const [showModal, setShowModal] = useState(false);
  const [teamAccountUser] = useTeamAccountUser();
  const userId = teamAccountUser?.id;
  const { activeTeamAccount } = useTeamAccount();
  const activeTeamAccountId = activeTeamAccount?.id;

  // to change if we allow mutliple trackers again:
  //const selectedDependency = useDependencyName() as string;
  const trackerId = campaign.trackers?.[0].id;
  const dependency = campaign.trackers?.[0].dependencies?.[0];

  const dashboardStats = api.campaignEvaluation.getDashboardStats.useQuery({
    campaignId: campaign.id,
    teamAccountId: activeTeamAccountId,
  }).data as {
    campaignId: string;
    insightsPerTracker: InsightsPerTracker;
    waitingEvalsCount: number;
    doneEvalsCount: number;
    repoCount: number;
    userCount: number;
    status: string;
    updatedAt: Date;
    error: string | null | undefined;
  };

  const {
    insightsPerTracker,
    waitingEvalsCount,
    doneEvalsCount,
    repoCount,
    userCount,
    status,
    updatedAt,
    error,
  } = dashboardStats ?? {};

  const someWaiting = status === "waiting";
  const campaignEvalError = error;

  const dependencyInsights =
    insightsPerTracker?.[trackerId]?.[dependency] ?? {};

  const noResult = Object.keys(dependencyInsights).length === 0;

  const noTargets = !repoCount && !userCount;

  const {
    primaryLeads,
    primaryNewLeads,
    primaryCustomers,
    leads,
    newLeads,
    customers,
    oneTimeDevs,
    partTimeDevs,
    fullTimeDevs,
    activeTeams,
  } = dependencyInsights;

  return (
    <div className="h-[calc(100vh_-_270px)]  overflow-scroll">
      {(noTargets || noResult) && (
        <div className="flex w-full gap-20 leading-none">
          <div className="w-full leading-6">
            Please make sure you have
            <Link
              className="text-accent"
              href={`/campaigns/${campaign.id}/dependency-tracking`}
            >
              {" "}
              added a dependency tracker
            </Link>
            ,{" "}
            <Link
              className="text-accent"
              href={`/campaigns/${campaign.id}/add-contact`}
            >
              at least one developer
            </Link>{" "}
            and{" "}
            <button className="text-accent" onClick={() => setShowModal(true)}>
              {" "}
              run evaluations{" "}
            </button>{" "}
            with said tracker to see relevant statistics.
          </div>
          <div>
            <SelectDependency defaultDependencyName={dependency} />
          </div>
        </div>
      )}
      {(someWaiting || campaignEvalError) && (
        <CampaignEvalProgress
          waitingEvalsCount={waitingEvalsCount}
          doneEvalsCount={doneEvalsCount}
        />
      )}

      {showModal && (
        <TrackerProvider initialTracker={campaign.trackers[0]}>
          <EvalSummaryModal
            setShowModal={setShowModal}
            showModal={showModal}
            campaign={campaign}
            userId={userId}
            teamAccountId={activeTeamAccountId}
          />
        </TrackerProvider>
      )}
      {dependency && !noTargets && !noResult && (
        <div className="w-full gap-20 leading-none">
          <div className="">
            {campaign.mode == Enums.CampaignMode.LIGHT ? (
              <LightDashboard
                leads={leads}
                customers={customers}
                repoCount={repoCount}
                userCount={userCount}
                dependency={dependency}
              />
            ) : (
              <>
                <PrimaryInsights
                  leads={primaryLeads}
                  newLeads={primaryNewLeads}
                  customers={primaryCustomers}
                  repoCount={repoCount}
                  userCount={userCount}
                  activeTeams={activeTeams}
                  dependency={dependency}
                />

                <SecondaryInsights
                  userCount={userCount}
                  leads={leads}
                  newLeads={newLeads}
                  customers={customers}
                />
                <KPIs
                  setSubMenu={setSubMenu}
                  leads={leads}
                  newLeads={newLeads}
                  customers={customers}
                />
              </>
            )}

            <RankInsights
              oneTimeDevs={oneTimeDevs}
              partTimeDevs={partTimeDevs}
              fullTimeDevs={fullTimeDevs}
            />
            <div className="my-4">
              Insights were last updated on {updatedAt.toLocaleDateString()}.
            </div>
          </div>
          {/*  <UsageGraph selectedDependency={dependency} /> */}
        </div>
      )}
    </div>
  );
}
